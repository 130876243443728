import React, { useEffect, useState, useContext, useCallback } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import PortableText from 'react-portable-text';
import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button, Container } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormModalContext from '../../context/FormModalContext';

import '../ContactForm/marketo-styles/styles.css';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
		},
	},
	contact: {
		paddingBottom: '4rem',
		// marginTop: '6rem',
	},
	disabled: {
		backgroundColor: '#4B5B69!important',
	},
	header: {
		color: theme.white,
		fontWeight: 600,
		textAlign: 'center',
		fontSize: '2.1rem',
		[theme.breakpoints.down('md')]: {
			lineHeight: 1.2,
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
	},
	subText: {
		color: theme.workwaveBlue,
		fontSize: '20px',
		textAlign: 'center',
	},
	formContainer: {
		background: theme.white,
		//border: `1px solid ${theme.lightGray}`,
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		borderRadius: '20px',
		//marginTop: '2rem',
		//padding: '16px',
		width: '100%',

		// [theme.breakpoints.down('md')]: {
		// 	maxWidth: '100%',
		// 	width: 'auto',
		// 	margin: '0 16px',
		// },
	},
	form: {
		maxHeight: 'auto',
		overflow: 'hidden',
		padding: '0 24px !important',
		// [theme.breakpoints.down('lg')]: {
		// 	maxHeight: '465px',
		// },
		[theme.breakpoints.down('md')]: {
			// maxHeight: '840px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '0 10px',
		},
	},
	profServFormRow: {
		'&:nth-child(even)': {
			paddingLeft: '8px',
			paddingRight: '0px !important',

			[theme.breakpoints.down(767)]: {
				paddingLeft: '0px !important',
				paddingRight: '0px !important',
			},
		},
		'&:nth-child(odd)': {
			paddingRight: '8px',
			paddingLeft: '0px !important',

			[theme.breakpoints.down(767)]: {
				paddingLeft: '0px !important',
				paddingRight: '0px !important',
			},
		},
	},
	profServFormRowFull: {
		paddingLeft: '0px !important',
		paddingRight: '0px !important',
	},
	formFooterText: {
		color: 'white',
		textAlign: 'center',
		fontSize: '2.1rem',
		marginTop: '2rem',
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
	},
}));

function MyDropzone({ value, name, handleChange }) {
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone();

	const files = acceptedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	return (
		<>
			<div
				{...getRootProps()}
				onChange={(e) => handleChange(e)}
				style={{
					background: 'white',
					padding: '2rem',
					borderRadius: '5px',
					border: '1px #5f6972 dashed',
				}}>
				<input {...getInputProps()} name={name} value={value} />
				{isDragActive ? (
					<p>Drop the files here ...</p>
				) : (
					<p>Drag 'n' drop some files here, or click to select files</p>
				)}
			</div>
			{files.length ? (
				<aside>
					<h4
						style={{ color: '#5f6972', fontSize: '1rem', fontWeight: 'bold' }}>
						Files
					</h4>
					<ul style={{ marginLeft: '15px' }}>{files}</ul>
				</aside>
			) : null}
		</>
	);
}

export const TechSupportExternalContactForm = ({
	formValues,
	modal,
	contactForm,
	endPoint,
	ctaText = 'SUBMIT',
	_rawFormFooterBody,
}) => {
	const classes = useStyles();
	const [filteredFormValues, setFilteredFormValues] = useState([]);
	const [fields, setFields] = useState(null);
	const [loading, setLoading] = useState(true);
	const [token, setToken] = useState(null);
	const [missingFields, setMissingFields] = useState(false);
	const [error, setError] = useState(false);
	const { header, privacyPolicy } = contactForm;
	const [responseLoading, setResponseLoading] = useState(false);

	// //stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	let initialState = {};

	useEffect(() => {
		const filtered = formValues.filter(
			(value) => value.fieldLabel && !value.conditionalLogic
		);
		setFilteredFormValues(filtered);
	}, []);

	useEffect(() => {
		//iterate over the fields array from sanity, set state to [field]: null

		for (let field of formValues) {
			initialState = {
				...initialState,
				[field.fieldValue]: null,
			};
		}

		//like the submit function below, eventually we'll have to gate for request specific functionality
		setFields({
			...initialState,
			//debug: '1',
			//ownerid: '00G4V000005Hz8u', // Not used for support
			RecordTypeId: '0124V000001hk30QAA', //realgreen
			//reason: 'Technical Support Request',
			Software_Program__c: 'RealGreen Software',
			Origin: 'Web',
			Type: 'Question',
		});

		setLoading(false);
	}, [formValues]);

	//all possible fields in fields on mount
	//copy of mapped formValues with conditional and hidden removed
	//when selected product matches, render new copy of mapped formvalues copy with the right field in it

	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		setFields({
			...fields,
			[name]: value,
		});

		//change this logic in the object literal below to match different conditional fields and change the spliced index below to insert the dropdown where you want
		if (name === 'Product_Functional_Area_Module__c') {
			const conditional = {
				API: null,
        SA4: 'sa4',
				SA5: 'sa5',
				AMA: 'ama',
				CAW: 'caw',
        MOL: 'mol',
        'RG Mobile App': 'rgmp',
        ServiceBot: null,
        ReviewBot: null,
				Communities: 'communities',
				'WorkWave Payments': 'wwpayments',
				'WorkWave University': 'wwuniversity',
        'WorkWave Forms': 'wwforms',
				'WorkWave Analytics': 'wwanalytics',
			}[value];

			if (conditional) {
				const filteredConditional = formValues.filter(
					(value) => value[conditional]
				);

				let filtered = formValues.filter(
					(value) => value.fieldLabel && !value.conditionalLogic
				);

				filtered.splice(7, 0, filteredConditional[0]);
				setFilteredFormValues([...filtered]);
			} else {
				const filtered = formValues.filter(
					(value) => value.fieldLabel && !value.conditionalLogic
				);
				setFilteredFormValues([...filtered]);
			}
		}
	};

	const handleFileUpload = (value) => {
		setFields({
			...fields,
			vx_list_files: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const requiredFields = formValues
			.filter((value) => value.required)
			.map((value) => value.fieldValue);

		const requiredMissing = Object.keys(fields).some((field) => {
			if (requiredFields.includes(field)) {
				if (fields[field] === null) {
					return true;
				}
			}
		});

		if (requiredMissing) {
			setMissingFields(true);
			return;
		}

		try {
			setResponseLoading(true);
			setMissingFields(false);
			setError(false);
			const response = await axios.post(endPoint, fields, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
					'Content-Type': 'application/json',
				},
			});
			navigate('/tech-support/thank-you/');
		} catch (err) {
			setError(err.response.data);
		} finally {
			resetForm();
			setResponseLoading(false);
		}
	};

	const resetForm = () => {
		const filtered = formValues.filter(
			(value) => value.fieldLabel && !value.conditionalLogic
		);

		setFilteredFormValues(filtered);
		for (let field of formValues) {
			initialState = {
				...initialState,
				[field.fieldValue]: null,
			};
		}
		setFields({
			...initialState,
			//debug: '1',
			//ownerid: '00G4V000005Hz8u', // Not used for support
			RecordTypeId: '0124V000001hk30QAA', //realgreen
			//reason: 'Technical Support Request',
			Software_Program__c: 'RealGreen Software',
			Origin: 'Web',
			Type: 'Question',
		});
	};

	const onChange = (value) => {
		setToken(value);
	};

	return (
		<Container>
			<div
				className={classes.contact}
				id='form'
				style={{
					padding: modal ? '1rem' : 0,
					marginBottom: '12rem', //!modal ? '2rem' : null,
				}}>
				<Grid container justify='center' alignItems='center'>
					{/* <Grid item className={classes.subText}>
            <Typography variant='body1' className={classes.subText}>
              {body}
            </Typography>
          </Grid> */}
					<Box className={classes.formContainer}>
						<div
							style={{
								background: '#055291',
								borderRadius: '16px 16px 0 0',
								padding: '.5rem 0',
								width: '100%',
								marginBottom: '1.5rem',
							}}>
							{header && (
								<Grid container justifyContent='center'>
									<Grid item xs={9} sm={10} md={11}>
										<Typography variant='h2' className={classes.header}>
											{header}
										</Typography>
									</Grid>
								</Grid>
							)}
						</div>
						<Grid container>
							{loading && !filteredFormValues.length ? (
								<div style={{ padding: '4rem 0', margin: '0 auto' }}>
									<CircularProgress />
								</div>
							) : (
								<form
									onSubmit={(e) => handleSubmit(e)}
									className={`mktoForm mktoHasWidth mktoLayoutLeft ${classes.form}`}>
									{/* iterate over the fields and set the value to the state value and the name to the sanity field name */}
									{filteredFormValues.length &&
										filteredFormValues.map((f, index) => {
											return (
												<Grid
													item
													md={
														f.fieldValue === 'Subject' ||
														f.fieldValue === 'Description'
															? 12
															: 6
													}
													key={index}
													className={
														f.fieldValue === 'Subject' ||
														f.fieldValue === 'Description'
															? `mktoFormRow fullWidth ${classes.profServFormRowFull}`
															: `mktoFormRow ${classes.profServFormRow}`
													}>
													<div
														className='mktoFieldDescriptor mktoFormControl'
														style={{ marginBottom: '10px' }}>
														<div
															className={
																!f.required
																	? `mktoFieldWrap`
																	: `mktoFieldWrap mktoRequiredField`
															}>
															<label
																for={f.fieldValue}
																style={{ display: f.attachment && 'none' }}
																className='mktoLabel mktoHasWidth'>
																{f.required ? (
																	<span
																		class='mktoAsterix'
																		style={{
																			color: '#BF0021',
																		}}>
																		*
																	</span>
																) : (
																	''
																)}
																{f.fieldLabel}
															</label>
															{f.externalFormSelect.length ? (
																<select
																	onChange={(e) => handleChange(e)}
																	value={fields[f.fieldValue]}
																	name={f.fieldValue}>
																	{f.externalFormSelect.map((select, index) => (
																		<option
																			key={index}
																			value={select.fieldValue}>
																			{select.fieldLabel}
																		</option>
																	))}
																</select>
															) : f.textArea ? (
																<textarea
																	onChange={(e) => handleChange(e)}
																	value={fields[f.fieldValue]}
																	name={f.fieldValue}
																	rows='5'
																	required={f.required}
																	className='mktoField mktoTextField mktoHasWidth'
																	placeholder=''
																	class='form-control'
																/>
															) : f.attachment ? null : ( // /> // 	handleChange={handleFileUpload} // 	name={f.fieldValue} // 	value={fields[f.fieldValue]} // <MyDropzone
																<input
																	onChange={(e) => handleChange(e)}
																	value={fields[f.fieldValue]}
																	name={f.fieldValue}
																	required={f.required}
																	className={
																		f.required
																			? 'mktoField mktoTextField mktoHasWidth mktoRequired'
																			: 'mktoField mktoTextField mktoHasWidth'
																	}
																	placeholder=''
																	class='form-control'
																/>
															)}
														</div>
														<div className='mktoClear' />
													</div>
												</Grid>
											);
										})}
									{/* render error message for missing fields or server error */}
									<Grid container direction='row' style={{ marginLeft: '' }}>
										<ReCAPTCHA
											sitekey='6LfHiJwUAAAAALrH_qANG96Zq4aUMl2i0WXZdRZ9'
											onChange={onChange}
										/>
									</Grid>

									{missingFields && (
										<Grid container direction='row' justify='center'>
											{' '}
											<Typography
												variant='body1'
												style={{
													color: '#BF0021',
													textAlign: 'center',
													marginTop: '1rem',
												}}>
												Please ensure you have included all required fields.
											</Typography>
										</Grid>
									)}
									{error && (
										<Grid container direction='row' justify='center'>
											{error}
										</Grid>
									)}
									<div
										style={{ clear: 'both' }}
										className='g-recaptcha'
										data-sitekey='6LfHiJwUAAAAALrH_qANG96Zq4aUMl2i0WXZdRZ9'></div>
									<Grid
										container
										direction='row'
										justify='center'
										className='mktoButtonRow'>
										{responseLoading ? (
											<CircularProgress />
										) : (
											<span className='mktoButtonWrap mktoSimple'>
												<Button
													color='primary'
													size='large'
													variant='contained'
													className='mktoButton'
													// classes={{
													// 	disabled: classes.disabled,
													// }}
													// disabled={responseLoading}
													style={{
														marginTop: missingFields || error ? 'none' : '1rem',
													}}
													onClick={(e) => handleSubmit(e)}>
													{ctaText}
												</Button>
											</span>
										)}
									</Grid>
									<Typography
										variant='body1'
										style={{
											marginTop: '2rem',
											marginBottom: '1rem',
											color: '#8394A2',
										}}>
										{privacyPolicy}
									</Typography>
								</form>
							)}
						</Grid>
					</Box>
					{/* <Grid item xs={12} md={8}>
						<Typography variant='body1' className={classes.formFooterText}>
							<PortableText
								content={_rawFormFooterBody}
								serializers={{
									normal: ({ children }) => <span>{children}</span>,
									strong: ({ children }) => (
										<span
											style={{
												textDecoration: 'underline',
												color: '#ffffff',
												fontWeight: 600,
												cursor: 'pointer',
											}}
											onClick={setFormModalOpen}>
											{children}
										</span>
									),
								}}
							/>
						</Typography>
					</Grid> */}
				</Grid>
			</div>
		</Container>
	);
};
