import React from 'react';

import { Typography, Container, Grid } from '@material-ui/core';
import { TechSupportCard } from './TechSupportCard';
import { ServiceSection } from './ServiceSection';

export const TechSupportBody = ({
	header,
	techSupportCards,
	serviceSectionBg,
	serviceSectionContent,
}) => {
	return (
		<>
			<Container>
				<Grid container justifyContent='center'>
					<Typography
						variant='h2'
						component='h1'
						color='primary'
						style={{ padding: '4rem 0' }}>
						{header}
					</Typography>
				</Grid>
				<Grid container style={{ paddingBottom: '2rem' }}>
					{techSupportCards.map((card, index) => (
						<TechSupportCard card={card} key={index} index={index} />
					))}
				</Grid>
			</Container>
			<ServiceSection
				content={serviceSectionContent}
				background={serviceSectionBg}
			/>
		</>
	);
};
